'use client';

import { Button, Container, Text, Title } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import { useRouter } from 'next/navigation';

export default function NotFound() {
  const router = useRouter();
  return (
    <Container size='xs' my='xl'>
      <Title>Page Not Found</Title>
      <Text my='xs'>Oh no! Looks like you&apos;re off route bud.</Text>
      <Button
        onClick={() => router.back()}
        leftSection={<IconChevronLeft />}
        my='md'
      >
        Go back
      </Button>
    </Container>
  );
}
